import {Container, Row, Col} from 'react-bootstrap'
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Hero from './components/Hero'

function App() {
  return (
   <div>
     <Header />
     <main>
       <Container>
         <Row className="py-3"> 
           <Col></Col>
           <Col className="col-6">
           <Hero />
           </Col>
           <Col></Col>
         </Row>
        
       </Container>
     </main>
     <Footer />

   </div>
  );
}

export default App;
