import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

const Footer = () => {
    return (
        <div>
            <footer>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col className='text-right py-3 footerText col-6'>
                            Copyright &copy; 2021
                        </Col>
                    </Row>
                </Container>
            </footer>
            
        </div>
    )
}

export default Footer
