import React from 'react'
//import hero from './hero.gif'
import hero from './Hero.png'

const Hero = () => {
    return (
        <div>
            <img className="heroImage" src={hero} alt="Hero" />
            <p className="heroText">A Hero is coming ....</p>
        </div>
    )
}

export default Hero
